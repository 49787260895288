import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select_v2, {
      onChange: _ctx.getAlter,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      options: _ctx.options,
      placeholder: "Please select",
      size: "large"
    }, null, 8, ["onChange", "modelValue", "options"])
  ]))
}