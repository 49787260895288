
import newline from "@/components/newline/index.vue";
import SelectV2 from "@/components/SelectV2/index.vue";
import * as echarts from "echarts";
import { orSo, warming, paowan } from "@/assets/echarts";
import moment from "moment";
import Api from "@/assets/api/api";

import Btn from "@/components/PublicButton/index.vue";

import workpiece from "./components/workpiece.vue";
import DatePicker from "@/components/DatePicker/index.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { ElMessage } from "element-plus";
import { filter } from "lodash";
export default defineComponent({
  name: "",
  components: { workpiece, DatePicker, Btn, newline, SelectV2 },
  setup() {
    const warm = ref();
    const curve = ref();
    const panwan_throw = ref();
    const panwan_throw2 = ref();
    const store = useStore();
    const data = reactive({
      echo: "",
      echo2: "",
      Time: "",
      value: "",
      btnList: [
        { id: 1, name: "加热淬火", highlight: true },
        { id: 2, name: "抛丸喷油", highlight: false },
      ],
      orSoData: <any>{},
      warmingData: <any>{},
      AllData: {},
      paowanData: <any>{},
      paowanData2: <any>{},
      list: [],
      IdShow: 1,
      Automation: {
        pageId: "100003", // 写死的值
        projectId: store.state.projectId,
        selectTime: "",
      },
      TimeList: <any>[],
      TimeList2: <any>[],
      AllTimeList: <any>[],
      TimeData: {
        produceItemCode: 100,
        projectId: store.state.projectId,
        selectDate: "2023-01-16",
      },
      TimerShaftData: {
        curveDateMinutes: "16:50:00",
        curveDateYmd: "2023-01-16",
        produceItemCode: 100,
        projectId: store.state.projectId,
      },
      temperatureCurveData: {
        curveItemBatchCode: "",
        produceItemCode: 100,
        projectId: store.state.projectId,
      },
      // 返回的数据
      pageTabs: <any>[],
      pageTabs2: <any>[],
      timeLineCodes: [],

      echartsShow: true, // 判断是否展示图表
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      // 获取当前时间
      // console.log(moment(new Date()).format("YYYY-MM-DD"));
      data.Time = moment(new Date()).format("YYYY-MM-DD");
      data.TimeData.selectDate = data.Time;
      data.TimerShaftData.curveDateYmd = data.Time;
      getTime();

      data.orSoData = orSo();
      data.warmingData = warming();
      data.paowanData = paowan();
      data.paowanData2 = paowan();
      setTimeout(() => {
        publicEcharts(warm.value, data.orSoData);
      }, 100);

      // 获取时间轴
    });

    // 公共echarts
    const publicEcharts = (Dom: any, Data: any) => {
      let myChart = echarts.init(Dom);
      myChart.setOption(Data);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };

    const getTimeValue = (value: any) => {
      let Time = moment(value).format("YYYY-MM-DD");
      data.TimeData.selectDate = Time;
      data.TimerShaftData.curveDateYmd = Time;
      getTime();
    };

    const getCut = (Id: any) => {
      data.IdShow = Id;
      getPageTabs();
      temperatureCurve();

      setTimeout(() => {
        if (Id == 1) {
          publicEcharts(warm.value, data.orSoData);
          // publicEcharts(curve.value, data.warmingData);
        } else {
          publicEcharts(panwan_throw.value, data.paowanData);
          publicEcharts(panwan_throw2.value, data.paowanData2);
        }
      }, 100);
    };
    // 首次进来获取工件类型时间
    const getTime = () => {
      Api.commonPost(
        "craft",
        "getTime",
        data.TimeData,
        (res: any) => {
          if (
            res.data.timeList.length > 0 &&
            res.data.timeList[0].minuteValues.length > 0
          ) {
            data.echartsShow = true;
            data.AllTimeList = res.data.timeList;
            data.TimeList = [];
            data.TimeList2 = [];
            data.TimerShaftData.curveDateMinutes = moment(
              res.data.timeList[0].minuteValues[0].upload
            ).format("HH:mm:ss");
            res.data.timeList.forEach((item: any, i: any) => {
              data.TimeList.push({
                label: item.hourDesc,
                value: item.hourDesc,
              });
              if (i == 0) data.echo = item.hourDesc;
              if (data.echo == item.hourDesc) {
                getHRMin(data.AllTimeList);
              }
            });

            TimerShaft();
          } else {
            data.Automation.selectTime = "2022-02-01";
            data.timeLineCodes = [];
            data.TimeList = [];
            data.TimeList2 = [];
            data.echo = "";
            data.echo2 = "";
            data.echartsShow = false;
            getbyPage();
          }
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    // 切换小时分钟下拉选择框
    const getHRMin = (AllTimeList: any) => {
      data.TimeList2 = [];
      AllTimeList.forEach((item: any, i: any) => {
        if (data.echo == item.hourDesc) {
          item.minuteValues.forEach((n: any, index: any) => {
            data.TimeList2.push({
              label: n.minutesDesc,
              value: n.upload,
            });
            if (index == 0) {
              data.echo2 = n.upload;
              data.TimerShaftData.curveDateMinutes = moment(data.echo2).format(
                "HH:mm:ss"
              );
              TimerShaft();
            }
          });
        }
      });
    };
    // 获取工件批次
    const TimerShaft = () => {
      Api.commonPost(
        "craft",
        "TimerShaft",
        data.TimerShaftData,
        (res: any) => {
          data.timeLineCodes = res.data.timeLineCodes;
          data.Automation.selectTime = res.data.timeLineCodes[0].curveDate;
          data.temperatureCurveData.curveItemBatchCode =
            res.data.timeLineCodes[0].curveItemBatchCode;
          getbyPage();
          temperatureCurve();
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    const getWorkpiece = (ITEN: any) => {
      data.Automation.selectTime = ITEN.curveDate;
      data.temperatureCurveData.curveItemBatchCode = ITEN.curveItemBatchCode;
      getbyPage();
      temperatureCurve();
    };
    const getbyPage = () => {
      // 获取加热淬火 抛丸区域
      Api.commonPost(
        "dap",
        "byPage",
        data.Automation,
        (res: any) => {
          data.pageTabs = JSON.parse(JSON.stringify(res.data.pageTabs));
          data.pageTabs2 = JSON.parse(JSON.stringify(res.data.pageTabs));
          getPageTabs();
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    const getPageTabs = () => {
      let pageTabs = JSON.parse(JSON.stringify(data.pageTabs2));
      pageTabs.forEach((item: any) => {
        if (item.id == "1000031") {
          item.flexwd = "50%";
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000131") {
              item.list = it.dataItemValues;
              item.list = getFilter(item.list);
            }
          });
        }

        if (item.id == "1000032") {
          item.flexwd = "50%";
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000121") {
              item.list = it.dataItemValues;
              item.list = getFilter(item.list);
            }
            // 加热炉折线图
            if (it.groupInfo.id == "10000122") {
              let list = it.dataItemValues;
              data.orSoData.xAxis[0].position = "top";
              data.orSoData.xAxis[0].data = list.map((it: any) => it.value);
              data.orSoData.xAxis[1].position = "bottom";
              data.orSoData.xAxis[1].data = list.map((it: any) => it.value);
              data.orSoData.xAxis[2].position = "top";
              data.orSoData.xAxis[2].data = list.map((it: any) => it.title);

              data.orSoData.series[1].data = list.map((it: any) => it.value);
            }
            if (it.groupInfo.id == "10000123") {
              let list = it.dataItemValues;
              data.orSoData.series[0].data = list.map((it: any) => it.value);
            }

            if (it.groupInfo.id == "10000124") {
              let list = it.dataItemValues;
              data.orSoData.series[3].data = list.map((it: any) => it.value); //设定温度
            }

            if (it.groupInfo.id == "10000125") {
              //运行温度
              let list = it.dataItemValues;

              data.orSoData.series[2].data = list.map((it: any) => it.value);
              setTimeout(() => {
                publicEcharts(warm?.value, data.orSoData);
              }, 100);
            }
          });
        }

        if (item.id == "1000033") {
          item.flexwd = "50%";
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000141") {
              item.list = it.dataItemValues;
              item.list = getFilter(item.list);
            }

            if (it.groupInfo.id == "10000142") {
              // 电机
              data.paowanData.title.text = it.groupInfo.groupTitle;
              data.paowanData.series[0].data = it.dataItemValues.map(
                (n: any) => +n.value + 2000
              );
              data.paowanData.series[1].data = it.dataItemValues.map(
                (n: any) => n.value
              );
              data.paowanData.series[2].data = it.dataItemValues.map(
                (n: any) => +n.value + 2000
              );
              data.paowanData.xAxis.data = it.dataItemValues.map(
                (n: any) => n.title
              );
            }
            if (it.groupInfo.id == "10000143") {
              // 电机
              data.paowanData.title.text = it.groupInfo.groupTitle;
              data.paowanData2.series[0].data = it.dataItemValues.map(
                (n: any) => +n.value + 2000
              );
              data.paowanData2.series[1].data = it.dataItemValues.map(
                (n: any) => n.value
              );
              data.paowanData2.series[2].data = it.dataItemValues.map(
                (n: any) => +n.value + 2000
              );
              data.paowanData2.xAxis.data = it.dataItemValues.map(
                (n: any) => n.title
              );
            }
          });
        }

        if (item.id == "1000034") {
          item.flexwd = "50%";
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000151") {
              item.list = it.dataItemValues;
              item.list = getFilter(item.list);
            }
          });
        }
      });

      pageTabs = pageTabs.map((item: any) => {
        if (data.IdShow == 1) {
          if (item.id == "1000031" || item.id == "1000032") {
            return item;
          }
        }
        if (data.IdShow == 2) {
          if (item.id == "1000033" || item.id == "1000034") {
            return item;
          }
        }
      });
      data.pageTabs = pageTabs.filter((item: any) => item != undefined);
    };

    const getFilter = (list: any) => {
      return list.filter((n: any) => {
        if (n.extJson && JSON.parse(n.extJson)?.showType == 1) {
        } else {
          return n;
        }
      });
    };

    // 曲线图
    const temperatureCurve = () => {
      Api.commonPost(
        "craft",
        "temperatureCurve",
        data.temperatureCurveData,
        (res: any) => {
          let warmingData = JSON.parse(JSON.stringify(warming()));
          let series = JSON.parse(JSON.stringify(warming().series[0]));
          let colorData = <any>[
            {
              color1: "62,253,255,0.55",
              color2: "62,253,255,0.25",
              color3: "62,253,255,0",
            },
            {
              color1: "16,216,70,0.55",
              color2: "16,216,70,0.25",
              color3: "16,216,70,0.0",
            },
            {
              color1: "172,49,224,0.55",
              color2: "172,49,224,0.25",
              color3: "172,49,224,0",
            },
            {
              color1: "224,100,49,0.55",
              color2: "224,100,49,0.25",
              color3: "224,100,49,0",
            },
            {
              color1: "224,49,199,0.55",
              color2: "224,49,199,0.25",
              color3: "224,49,199,0",
            },
          ];
          warmingData.series = [];
          warmingData.xAxis[0].data = [];
          res.data.lines.forEach((item: any, i: any) => {
            warmingData.xAxis[0].data = item.pointDtoList.map(
              (it: any) => it.name
            );
            series.data = item.pointDtoList.map((it: any) => it.value);
            if (i == 0) {
              series.data = [90, 120, 160, 180, 220];
            }
            if (i == 1) {
              series.data = [30, 50, 60, 80, 120];
            }
            series.areaStyle.normal.color.colorStops.forEach(
              (color: any, colindex: any) => {
                series.areaStyle.normal.color.colorStops[
                  colindex
                ].color = `RGBA(${
                  colorData[colindex]?.[`color${colindex + 1}`]
                })`;
              }
            );

            series.lineStyle.color.colorStops.forEach(
              (color: any, colindex: any) => {
                series.lineStyle.color.colorStops[colindex].color = `RGBA(${
                  colorData[colindex]?.[`color${colindex + 1}`]
                })`;
              }
            );
            console.log("克隆数据", warmingData, warming(), series);

            warmingData.series.push(series);
            series = JSON.parse(JSON.stringify(warming().series[0]));
            // series = series;
          });
          console.log(warmingData);
          publicEcharts(curve.value, warmingData);
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    // 时间选择切换
    const getSelect1 = (e: any) => {
      data.echo = e;
      getHRMin(data.AllTimeList);
    };
    const getSelect2 = (e: any) => {
      data.echo2 = e;
      data.TimerShaftData.curveDateMinutes = moment(e).format("HH:mm:ss");
      TimerShaft();
    };
    return {
      ...toRefs(data),
      getTimeValue,
      warm,
      curve,
      panwan_throw,
      panwan_throw2,
      getCut,
      getWorkpiece,
      getSelect1,
      getSelect2,
    };
  },
});
