import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2a46ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.show ? 'row al-c' : ''])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          onClick: ($event: any) => (_ctx.getCut(item)),
          class: _normalizeClass([[item.highlight ? 'btn2' : '', !_ctx.show ? 'margin_top' : ''], "btn row al-c ju-c"])
        }, [
          _createElementVNode("p", null, _toDisplayString(item.name), 1)
        ], 10, _hoisted_1))
      }), 128))
    ], 2)
  ]))
}