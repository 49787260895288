
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    list: {
      type: Array,
    },
  },
  setup(props, cxt) {
    const data = reactive({
      workpieceList: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getList(props.list);
    });
    watch(
      props,
      (newValue) => {
        getList(newValue.list);
      },
      { deep: true }
    );

    const getList = (Data: any) => {
      data.workpieceList = JSON.parse(JSON.stringify(Data));
      data.workpieceList.forEach((item: any, i: any) => {
        if (i == 0) {
          item.highlight = true;
        }
      });
    };
    const getCut = (It: any) => {
      data.workpieceList.forEach((item: any) =>
        item.curveItemBatchCode == It.curveItemBatchCode
          ? (item.highlight = true)
          : (item.highlight = false)
      );
      cxt.emit("getWorkpiece", It);
    };
    return {
      ...toRefs(data),
      getCut,
    };
  },
});
