
import { number } from "echarts";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    List: {
      type: Array,
    },
    echo: {
      type: String,
      default: "",
    },
  },
  setup(props, cxt) {
    const data = reactive({
      value: "",
      options: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getOptions(props.List, props.echo);
    });
    watch(
      props,
      (newValue) => {
        getOptions(newValue.List, newValue.echo);
      },
      { deep: true }
    );

    const getOptions = (Data: any, echo: any) => {
      data.options = Data;
      data.value = echo;
    };

    const getAlter = (e: any) => {
      cxt.emit("getSelect", e);
    };
    return {
      ...toRefs(data),
      getOptions,
      getAlter,
    };
  },
});
